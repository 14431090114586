import * as React from 'react';
import { ANALYTICS_CONSTANTS, trackEDDL } from '../../../../utilities/analytics';
import { ITrackingPayload } from '../../../../types/analytics';

interface IPreapprovedBudgetProps {
    stationUrl: string;
}

function PreapprovedBudget(props: IPreapprovedBudgetProps) {
    const handleSearchClick = () => {
        const payload: ITrackingPayload = {
            event: ANALYTICS_CONSTANTS.EVENTS.CLICK.SEARCH_INITIATED,
            linkDetails: {
                name: 'search with my budget',
                position: 'Homepage',
            },
            search: {
                initiationMethod: 'Homepage|search with my budget|Homepage',
            },
        };

        trackEDDL(payload);

        setTimeout(() => {
            window.location.href = '/cars/all';
        }, 250);
    };

    const handleReviewClick = (e: any) => {
        e.preventDefault();
        const payload: ITrackingPayload = {
            event: ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA,
            linkDetails: {
                name: 'review my financing',
                position: 'Homepage',
            },
        };

        trackEDDL(payload);

        setTimeout(() => {
            window.location.href = props.stationUrl;
        }, 250);
    };

    return (
        <div className="preapproved-budget-container max-width--xl">
            <div className="preapproved-budget-card-container">
                <div className="preapproved-budget-card-img" role="img" aria-hidden="true" />
                <div className="preapproved-budget-card-img-label" role="img" aria-hidden="true" />
            </div>
            <div className="preapproved-budget-details">
                <h2 className="preapproved-budget-heading">
                    Want to browse for cars that match your financing&nbsp;results?
                </h2>
                <hzn-button
                    onClick={handleSearchClick}
                    type="button"
                    class="preapproved-budget-primary-button"
                    href="/cars/all"
                >
                    Search with my budget
                </hzn-button>
                <button onClick={handleReviewClick} className="preapproved-budget-tertiary-button" type="button">
                    Review my financing
                </button>
            </div>
        </div>
    );
}

export default PreapprovedBudget;
