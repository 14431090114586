export const getSavedVehicles = (componentCallback: (stockNumberList: number[]) => any) => {
    const event = new CustomEvent('kmxGetSavedCars', {
        bubbles: true,
        detail: {
            callback: (result: Promise<any>) => {
                result.then((stockNumberList: number[]) => {
                    componentCallback(stockNumberList);
                });
            },
        },
    });
    document.documentElement.dispatchEvent(event);
};

export const setSavedVehicle = (sn: number, componentCallback?: (success: boolean) => any) => {
    const event = new CustomEvent('kmxAddSavedCar', {
        bubbles: true,
        detail: {
            stockNumber: sn,
            callback: (result: Promise<any>) => {
                toggleFavoritesResponse(result, componentCallback);
            },
        },
    });
    document.documentElement.dispatchEvent(event);
};

export const removeSavedVehicle = (sn: number, componentCallback?: (success: boolean) => any) => {
    const event = new CustomEvent('kmxRemoveSavedCar', {
        bubbles: true,
        detail: {
            stockNumber: sn,
            callback: (result: Promise<any>) => {
                toggleFavoritesResponse(result, componentCallback);
            },
        },
    });
    document.documentElement.dispatchEvent(event);
};

const toggleFavoritesResponse = (response: Promise<any>, componentCallback?: (success: boolean) => any) => {
    response.then((success: boolean) => {
        success && componentCallback && componentCallback(success);
    });
};

export const loadSavedVehiclesAssets = (done: () => any) => {
    const savedVehiclesCSSDataEl = document.querySelector('[data-saved-vehicles-css-src]') as any;
    const savedVehiclesJSDataEl = document.querySelector('[data-saved-vehicles-js-src]') as any;
    const savedVehiclesCSSSrc = savedVehiclesCSSDataEl.getAttribute('data-saved-vehicles-css-src');
    const savedVehiclesJSSrc = savedVehiclesJSDataEl.getAttribute('data-saved-vehicles-js-src');

    const linkEl = document.createElement('link');
    linkEl.setAttribute('rel', 'stylesheet');
    linkEl.setAttribute('type', 'text/css');
    linkEl.setAttribute('href', savedVehiclesCSSSrc);

    const scriptEl = document.createElement('script');
    scriptEl.setAttribute('type', 'text/javascript');
    scriptEl.setAttribute('src', savedVehiclesJSSrc);

    scriptEl.onload = () => {
        linkEl.onload = () => done();
        document.getElementsByTagName('head')[0].appendChild(linkEl);
    };

    document.getElementsByTagName('head')[0].appendChild(scriptEl);
};
