import { GRAND_OPENING_ACTIVE_STATUS, SET_STORE_BY_ID_CUSTOM_EVENT } from '../../constants';
import { content } from '../../content';
import { COOKIES, getCookieObject, getStoreId, getStoreZipCode, setCookie, stringifyCookie } from '../../storage/cookie';
import { IPayload } from '../../types/analytics';
import { Store } from '../../types/store';
import { ANALYTICS_CONSTANTS, track } from '../../utils/analytics';
import { determineNewStoreSelectionUrl } from '../../utils/search';
import * as storesApi from '../nearestStore/storesApi';
import StoreStatus from '../storeHours/storeStatus';

export const buildStoreSearchFormAndResults = () => {
    // Structure the search form
    const searchFormHTML = document.createElement('form');
    searchFormHTML.classList.add(content.STORE_CHOOSER.MODAL.FORM.class);
    searchFormHTML.id = content.STORE_CHOOSER.MODAL.FORM.id;
    searchFormHTML.onsubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(searchFormHTML);
        const keywordInput = formData.get('keyword') as string;

        if (keywordInput) { initiateNewSearch(keywordInput) }
        else { buildErrorState() }
    }
    // Create input element
    const searchInputHTML = document.createElement('input');
    searchInputHTML.className = content.STORE_CHOOSER.MODAL.FORM.INPUT.class;
    searchInputHTML.id = content.STORE_CHOOSER.MODAL.FORM.INPUT.id;
    searchInputHTML.name = content.STORE_CHOOSER.MODAL.FORM.INPUT.name;
    searchInputHTML.placeholder = content.STORE_CHOOSER.MODAL.FORM.INPUT.placeholder;
    searchInputHTML.maxLength = 25;
    // Create button for submitting form (magnifying glass)
    const searchSubmitButtonHTML = document.createElement('button');
    searchSubmitButtonHTML.className = content.STORE_CHOOSER.MODAL.FORM.BUTTON.class;
    searchSubmitButtonHTML.innerHTML = content.STORE_CHOOSER.MODAL.FORM.BUTTON.innerHTML;
    searchSubmitButtonHTML.setAttribute('aria-label', content.STORE_CHOOSER.MODAL.FORM.BUTTON.ariaLabel);
    searchSubmitButtonHTML.setAttribute('aria-hidden', content.STORE_CHOOSER.MODAL.FORM.BUTTON.ariaHidden);
    searchSubmitButtonHTML.setAttribute('type', content.STORE_CHOOSER.MODAL.FORM.BUTTON.type);
    // Bring the HTML together
    searchFormHTML.appendChild(searchInputHTML);
    searchFormHTML.appendChild(searchSubmitButtonHTML);
    listenForCustomEventToInitiateSearch();
    return searchFormHTML;
}

function initiateNewSearch(keyword: string) {
    const foundStoreModalBody = document.querySelector(`#${content.STORE_CHOOSER.MODAL.BODY.id}`);
    const foundStoreModalClose: HTMLButtonElement | null = document.querySelector(`.${content.STORE_CHOOSER.MODAL.HEADER.CLOSE.class}`);
    if (foundStoreModalClose && foundStoreModalBody && foundStoreModalBody.innerHTML === '') { foundStoreModalClose.focus(); }
    if (foundStoreModalBody) {
        foundStoreModalBody.innerHTML = content.STORE_CHOOSER.MODAL.BODY.loadingInnerHTML;
        // render the loading indicator above until the API completes below
        if (keyword) {
            storesApi.getStoresByKeyword(getStoreZipCode(), keyword, 30, (stores: Store[] | null, allStores: Store[] | null) => {
                if (stores) {
                    const yourStoreId = getStoreId();
                    if (stores.filter(store => store.Id === yourStoreId).length == 0) {
                        storesApi.getStore(yourStoreId, (yourStore: Store) => {
                            if (allStores) {
                                const found = allStores.find((s) => s.Id === yourStoreId);
                                if (found) {
                                    yourStore.DistanceInMiles = found.DistanceInMiles;
                                }
                            }
                            buildDisplayableListOfStores(stores, yourStoreId, yourStore)
                        });
                    } else {
                        const yourStores = stores.sort((a, b) => { return yourStoreId === a.Id ? -1 : yourStoreId === a.Id ? 1 : 0 })
                        buildDisplayableListOfStores(yourStores, yourStoreId)
                    }
                } else {
                    buildErrorState();
                }
            })
        } else {
            buildErrorState();
        }
    }
}

function buildDisplayableListOfStores(stores: Store[], yourStoreId: string, yourStore?: Store) {
    const activeStores = stores.filter(store => store != null
        && (store.IsSaveable || store.ActiveStatus === GRAND_OPENING_ACTIVE_STATUS));
    buildListOfStores(activeStores, yourStoreId, yourStore);
}

// "YourStore" needs to be handled separately from the list
// So that we can properly determine the no-matches case if the only store returned is your store disregarding the safety fetch.
function buildListOfStores(stores: Store[], yourStoreId: string, yourStore?: Store) {
    const foundModal = document.getElementById(content.STORE_CHOOSER.MODAL.BODY.id);
    if (!foundModal) {
        return;
    }

    foundModal.innerHTML = ''

    if(!stores || stores.length == 0) {
        const noMatchesFound = document.createElement('div');
        noMatchesFound.innerHTML = content.STORE_CHOOSER.MODAL.BODY.noMatchesFoundInnerHtml;
        foundModal.appendChild(noMatchesFound);
    }

    const yourStores = yourStore? ([yourStore]).concat(stores): stores;

    if (yourStores) {
        const storesWrapper = document.createElement('ul');
        storesWrapper.classList.add(content.STORE_CHOOSER.MODAL.BODY.LIST.class);

        for (let s = 0; s < 30; s++) {
            if (yourStores[s] && yourStores[s].Id) {
                storesWrapper.appendChild(renderStoreItem(yourStores[s], yourStoreId === yourStores[s].Id));
            }
        }

        foundModal.appendChild(storesWrapper);
        const allStoresLink = document.createElement('a');
        allStoresLink.className = content.STORE_CHOOSER.MODAL.BODY.LIST.STORE.allStoreLinkClass;
        allStoresLink.href = content.STORE_CHOOSER.MODAL.BODY.LIST.STORE.allStoreLinkHref;
        allStoresLink.text = content.STORE_CHOOSER.MODAL.BODY.LIST.STORE.allStoreLinkText;
        storesWrapper.appendChild(allStoresLink);
    }
}

function buildErrorState() {
    const errorWrapper = document.createElement('div');
    errorWrapper.classList.add(content.STORE_CHOOSER.MODAL.BODY.ERROR.class);
    errorWrapper.innerHTML = content.STORE_CHOOSER.MODAL.BODY.ERROR.innerHTML;
    const foundModal = document.getElementById(content.STORE_CHOOSER.MODAL.BODY.id);
    if (foundModal) {
        foundModal.innerHTML = ''
        foundModal.appendChild(errorWrapper);
    }
}

export function determineSection(referenceEl: HTMLElement) {
    const header = referenceEl?.closest('header');
    const footer = referenceEl?.closest('footer');
    const location = header && 'header' || footer && 'footer' || 'unknown';
    return location;
}


function renderStoreItem(store: Store, storeIsSelectedStore: boolean) {
    const newStoreItemHTML = document.createElement('li');
    newStoreItemHTML.classList.add(content.STORE_CHOOSER.MODAL.BODY.LIST.STORE.class);
    const storeStatus = new StoreStatus(store);

    function reloadPageToUpdateDate() {
        window.location.reload();
    }

    function selectNewStoreAction(el: HTMLElement | null) {
        //update kmx visitor cookie with user-entered zip code
        const searchFormElement: HTMLFormElement | null = document.querySelector(`#${content.STORE_CHOOSER.MODAL.FORM.id}`);
        if(searchFormElement) {
            const formData = new FormData(searchFormElement);
            const keywordInput = formData.get('keyword') as string;
            const visitorCookie = getCookieObject(COOKIES.visitor);

            //check if the user's input is a zipcode
            var matches = keywordInput.match(/^\d{5}$/g);
            if (matches != null){
                visitorCookie.StoreSearchZipCode = keywordInput;
            }
            else {
                visitorCookie.StoreSearchZipCode = undefined;
            }
            setCookie(COOKIES.visitor, stringifyCookie(visitorCookie), undefined);
        }

        const newUrl = determineNewStoreSelectionUrl();
        history.pushState({}, '', newUrl);
        const selectStoreEvent = new CustomEvent(SET_STORE_BY_ID_CUSTOM_EVENT, { detail: { id: store.Id, distanceInMiles: store.DistanceInMiles, done: () => reloadPageToUpdateDate() } })
        document.documentElement.dispatchEvent(selectStoreEvent);
        const position = el && determineSection(el);
        const payload = {
            "event": ANALYTICS_CONSTANTS.EVENTS.setStore,
            "linkDetails": {
                "name": "Set as my store",
                "position": `${position}-location`,
            },
            "storeSelected": store.Name
        } as IPayload;
        track(payload);
    }

    newStoreItemHTML.innerHTML = content.STORE_CHOOSER.MODAL.BODY.LIST.STORE.itemInnerHTML(
        store.Name,
        store.DistanceInMiles,
        store.AddressLine1,
        store.MailingCity,
        store.StateAbbreviation,
        store.ZipCode,
        store.AlertMessage,
        storeStatus.hoursText,
        store.IsSaveable);

    // Each store item button selection needs to generated this way in order to add click events
    const storeSelectorWrapperHtml = document.createElement('div');
    storeSelectorWrapperHtml.classList.add(content.STORE_CHOOSER.MODAL.BODY.LIST.STORE.selectClass);
    // Create elements for building on
    const storeNotSaveableSelectedHTML = document.createElement('div');
    const storeSelectorButtonHtml = document.createElement('button');
    // Only build the elements out if we need to per store (performance improvement and probably could be refactored to not have the ternary below)
    if (storeIsSelectedStore) {
        storeNotSaveableSelectedHTML.className = content.STORE_CHOOSER.MODAL.BODY.LIST.STORE.selectSelectedClass;
        storeNotSaveableSelectedHTML.innerHTML = content.STORE_CHOOSER.MODAL.BODY.LIST.STORE.selectSelectedInnerHTML;
    } else if (store.ActiveStatus === GRAND_OPENING_ACTIVE_STATUS) {
        storeNotSaveableSelectedHTML.className = content.STORE_CHOOSER.MODAL.BODY.LIST.STORE.selectGrandOpenClass;
        storeNotSaveableSelectedHTML.innerHTML = content.STORE_CHOOSER.MODAL.BODY.LIST.STORE.selectGrandOpenInnerHTML;
    } else {
        storeSelectorButtonHtml.className = content.STORE_CHOOSER.MODAL.BODY.LIST.STORE.selectButtonClass;
        storeSelectorButtonHtml.innerText = content.STORE_CHOOSER.MODAL.BODY.LIST.STORE.selectButtonInnerText;
        storeSelectorButtonHtml.setAttribute('type', content.STORE_CHOOSER.MODAL.BODY.LIST.STORE.selectButtonType);
        storeSelectorButtonHtml.setAttribute('aria-label', content.STORE_CHOOSER.MODAL.BODY.LIST.STORE.selectButtonAriaLabel);
        storeSelectorButtonHtml.setAttribute('aria-hidden', content.STORE_CHOOSER.MODAL.BODY.LIST.STORE.selectButtonAriaHidden);
        storeSelectorButtonHtml.setAttribute('data-kmx-analytics', `prop5=Header-Location | location=${store.Name}`);
        storeSelectorButtonHtml.onclick = (event: Event) => selectNewStoreAction(event.target as HTMLElement);
    }
    // TODO: figure out how to name things and refactor so we dont need this ternary here but just the if else above
    storeSelectorWrapperHtml.appendChild((storeIsSelectedStore || !store.IsSaveable) ? storeNotSaveableSelectedHTML : storeSelectorButtonHtml);
    // Combine elements to build HTML
    newStoreItemHTML.appendChild(storeSelectorWrapperHtml);
    return newStoreItemHTML;
}

function listenForCustomEventToInitiateSearch() {
    function openModal(e: any) {
        const searchInput = document.querySelector(`#${content.STORE_CHOOSER.MODAL.FORM.INPUT.id}`);
        const foundSearchInput: HTMLInputElement | null = searchInput as HTMLInputElement;
        const foundBodyElement = document.getElementsByTagName('body')[0] as HTMLBodyElement;
        const foundBaseHtmlElement = document.getElementsByTagName('html')[0] as HTMLHtmlElement;
        if (foundBodyElement) { foundBodyElement.classList.add(content.CLASSNAMES.bodyScrollLock) };
        if (foundBaseHtmlElement) { foundBaseHtmlElement.classList.add(content.CLASSNAMES.bodyScrollLock) };
        if (foundSearchInput) {
            foundSearchInput.value = e.detail.keyword;
            const foundModalWrapper = document.querySelector(`#${content.STORE_CHOOSER.WRAPPER.id}`);
            if (foundModalWrapper && !foundModalWrapper.classList.contains(content.STORE_CHOOSER.MODAL.FORM.visibleClass)) {
                foundModalWrapper.classList.add(content.STORE_CHOOSER.MODAL.FORM.visibleClass);
                initiateNewSearch(e.detail.keyword);
            }
        }
        const foundCloseIcon: HTMLButtonElement | null = document.querySelector(`.${content.STORE_CHOOSER.MODAL.HEADER.CLOSE.class}`);
        foundCloseIcon && foundCloseIcon.focus();
    }
    window.addEventListener(content.STORE_CHOOSER.EVENT_NAMES.headerKeywordInput, openModal);
    window.addEventListener(content.STORE_CHOOSER.EVENT_NAMES.footerKeywordInput, openModal);
}
