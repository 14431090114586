import { addCustomElement, manageChatFab, minimizeChatWindow } from '../utils';
import { data, testData } from '../data';
import { IMenuItem } from '../types';
import '../kmx-thumb-nav-menu';
import './kmx-thumb-bar-item';
import { appElId } from '../constants';
import { navStore, IMenuData } from '../store';
import { ANALYTICS_CONSTANTS, track} from '../../../utils/analytics'
import { MR_CUTE_TEST_CUSTOM_EVENT } from '../constants';
import { IPayload } from '../../../types/analytics';

// The SCSS file should compile to this and I'm trying out placing a constant here.
const componentLevelPrefix = `${appElId}__thumb-bar`;

class ThumbBar extends HTMLElement {


    constructor() {
        super();
    };

    clickedMenu = false;
    hasScrolled = false;

    renderTemplate(menuItems: IMenuItem[]) {
        return `
                <heading id="navHeading" class="visually-hidden">Main Navigation</heading>
                <nav class="${componentLevelPrefix}" role="navigation" aria-labelledby="navHeading">
                    ${this.renderMenuItems(menuItems)}
                </nav>
        `;
    };

    connectedCallback() {

        console.log ( ' override is running ');
        let testVariant : string | undefined = undefined;
        let loggedIn: boolean = false;
        const { setActiveTestVariant } = navStore.getState() as IMenuData;
        navStore.subscribe(({ currentTitle, isThumbNavMenuOpen, isSignedIn, activeTestVariant }: any) => {
            this.toggleNavBarOpen(isThumbNavMenuOpen);
            prevTitle = currentTitle;
            loggedIn = isSignedIn;
            testVariant = activeTestVariant;
        });

        window.addEventListener(MR_CUTE_TEST_CUSTOM_EVENT, ((event:CustomEventInit) => {
                setActiveTestVariant(event.detail);
                this.setAnimatedProfile(loggedIn, testVariant);
        }));

        this.innerHTML = this.renderTemplate(data.menuItems);

        let prevTitle : string | undefined = undefined;

        this.createButtonEventListeners();

        this.setUpMenuFadeOnScroll();

        manageChatFab();
    };

    setAnimatedProfile(loggedIn?: boolean, activeTestVariant?: string | undefined, prevTitle?: string | undefined){
        const visits = sessionStorage.getItem('com.adobe.reactor.core.visitorTracking.pagesViewed');

        let shouldAnimate = !loggedIn && ( visits === null || visits === '1' ) && activeTestVariant === MR_CUTE_TEST_CUSTOM_EVENT && !this.clickedMenu && !this.hasScrolled;

        if(shouldAnimate){
            const payload = {
                "event": ANALYTICS_CONSTANTS.ANIMATION.animationFired,
                "linkDetails": {
                    "name": ANALYTICS_CONSTANTS.ANIMATION.animationFired,
                    "position": ANALYTICS_CONSTANTS.LOCATIONS.header
                }
            } as IPayload
            track(payload)
            this.innerHTML = this.renderTemplate(testData.menuItems);
            this.createButtonEventListeners();
        }
        else{
            this.innerHTML = this.renderTemplate(data.menuItems);
            if(prevTitle){
                this.createButtonEventListeners(prevTitle);
                navStore.setState({prevTitle});
            }
            else{
                this.createButtonEventListeners();
            }
            this.setUpMenuFadeOnScroll();
        }
    }

    createButtonEventListeners(prevTitle?: string | undefined){
        navStore.subscribe(({ currentTitle }: any) => {
            prevTitle = currentTitle;
        });

        const { onNavItemClick, activeTestVariant } = navStore.getState() as IMenuData;

        const topLevelNavButtons = this.querySelectorAll('.kmx-hf__thumb-bar__item__button');

        topLevelNavButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                const clickedButton = e.currentTarget as HTMLElement;
                const title = clickedButton.dataset.title;
                minimizeChatWindow();
                if (title) {
                    onNavItemClick({ title, prevTitle });
                    const name = title;
                    const payload = {
                        "linkDetails": {
                            "name": name,
                            "position": ANALYTICS_CONSTANTS.LOCATIONS.header
                        }
                    } as IPayload
                    track(payload);
                };

                if(activeTestVariant === MR_CUTE_TEST_CUSTOM_EVENT && this.clickedMenu === false){
                    this.clickedMenu = true;
                    this.setAnimatedProfile(undefined, undefined ,prevTitle);
                }
            });
        });
    }

    setUpMenuFadeOnScroll() {
        const scrollEffectClass = 'bg-glass--gray';
        let navEl: (HTMLElement | null) = document.querySelector(`.${componentLevelPrefix}`);
        let test : string | undefined = "";

        navStore.subscribe(({ activeTestVariant }: any) => {
            test = activeTestVariant;
        });

        window.addEventListener('scroll', e => {
            if ( navEl ) {
                window.scrollY > 50 ? navEl.classList.add(scrollEffectClass) : navEl.classList.remove(scrollEffectClass);
            }

            if(this.hasScrolled === false && test === MR_CUTE_TEST_CUSTOM_EVENT){
                this.hasScrolled = true;
                this.setAnimatedProfile();
            }
        });
    };

    renderMenuItems(menuItems: IMenuItem[]) {
        return menuItems.map(({ title, icon, kmxAnalytics }) => {
            return `
                <kmx-thumb-bar-item
                    data-title="${title}"
                    data-icon="${icon}"
                    data-kmx-analytics="${kmxAnalytics}">
                </kmx-thumb-bar-item>
            `
        }).join('');
    };

    toggleChatFab(isThumbNavMenuOpen: boolean) {
        const style = isThumbNavMenuOpen ? 'hidden' : 'visible';
        const onLoadChatFab = document.getElementById('startChatFab')
        const postInteractionChatFab = document.getElementById('embeddedChatFab')
        onLoadChatFab ? onLoadChatFab.style.visibility = style : null;
        postInteractionChatFab ? postInteractionChatFab.style.visibility = style : null;
    }

    toggleNavBarOpen(isThumbNavMenuOpen: boolean) {
        const navBar = this.querySelector(`.${componentLevelPrefix}`);
        this.toggleChatFab(isThumbNavMenuOpen);


        if (navBar) {
            if (isThumbNavMenuOpen) {
                navBar.classList.add(`${componentLevelPrefix}--open`);
            } else {
                navBar.classList.remove(`${componentLevelPrefix}--open`);
            }
        };
    };
};

const KMX_THUMB_BAR = 'kmx-thumb-bar';

addCustomElement(KMX_THUMB_BAR, ThumbBar);

