import * as React from 'react';
import { gsap } from 'gsap';
import { IRecentSearchTile } from '../../utilities/types';
import Icons from '../../../../utilities/icons';
import RecentSearchTileHeader from './RecentSearchTileHeader';
import RecentSearchTileImg from './RecentSearchTileImg';
import TileKebabMenu from '../tile-kebab-menu/TileKebabMenu';
import TileKebabMenuItem from '../tile-kebab-menu/TileKebabMenuItem';
import TileKebabMenuItemSnackbar from '../tile-kebab-menu/TileKebabMenuItemSnackbar';
import addQueryParam from '../../utilities/addQueryParam';
import { trackEDDL, ANALYTICS_CONSTANTS } from '../../../../utilities/analytics';
import { ITrackingPayload } from '../../../../types/analytics';

interface IRecentSearchTileProps {
    linkAttrs?: { [key: string]: any };
    recentSearchTile: IRecentSearchTile;
    isLoading?: boolean;
}

const RecentSearchTile = (props: IRecentSearchTileProps) => {
    const {
        title,
        link,
        newMatchCountText,
        newMatchCount,
        inventoryCount,
        inventoryCountText,
        imageUrl,
        imageAlt,
        isPartialRecentSearch,
    } = props.recentSearchTile;
    const linkAttrs = props.linkAttrs ? props.linkAttrs : {};

    const [imgLoaded, setImgLoaded] = React.useState(false);
    const [isShareSnackbarOpen, setIsShareSnackbarOpen] = React.useState<boolean>(false);
    const [tileKebabMenuDialogOpen, setTileKebabMenuDialogOpen] = React.useState(false);

    const loadingBarRef = React.useRef(null);

    React.useEffect(() => {
        const tween = gsap.timeline({ repeat: -1 });

        if (props.isLoading || !imgLoaded) {
            tween.fromTo(
                loadingBarRef.current,
                { translateX: '-48px' },
                {
                    translateX: '421px',
                    ease: 'none',
                    duration: 0.9,
                }
            );
        } else {
            tween.kill();
        }
    });

    const handleShareClick = async () => {
        await navigator.clipboard.writeText(`${window.location.origin}${link}`);
        setIsShareSnackbarOpen(true);
    };

    const KEBAB_MENU_ITEMS = [
        {
            text: 'Share',
            icon: Icons.share,
            handleClick: (e: any) => {
                e.preventDefault();
                handleShareClick();
                const payload: ITrackingPayload = {
                    event: ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA,
                    linkDetails: {
                        name: 'share',
                        position: `Searched:${title}`,
                    },
                };
                trackEDDL(payload);
            },
            href: '#share',
            linkClasses: 'tile-menu-item--share',
        },
    ];

    const buildTileMenuItems = () => {
        const tileMenuItems: JSX.Element[] = [];

        KEBAB_MENU_ITEMS.forEach((kebabMenuItem, i) => {
            tileMenuItems.push(<TileKebabMenuItem key={i} {...kebabMenuItem} />);
        });

        return tileMenuItems;
    };

    return (
        <div className="kmx-recent-search-tile">
            <figure className="kmx-recent-search-tile-figure">
                {(props.isLoading || !imgLoaded) && (
                    <div className="kmx-recent-search-tile-loading-bar-container">
                        <div ref={loadingBarRef} className="kmx-recent-search-tile-loading-bar" />
                    </div>
                )}
                <a
                    className="kmx-recent-search-tile-link"
                    href={!newMatchCount ? link : addQueryParam(link, 'newMatch=true')}
                    {...linkAttrs}
                    tabIndex={0}
                >
                    <RecentSearchTileHeader
                        title={title}
                        newMatchCountText={newMatchCountText}
                        inventoryCount={inventoryCount}
                        inventoryCountText={inventoryCountText}
                        isPartialRecentSearch={isPartialRecentSearch}
                    />
                    <div className="kmx-recent-search-tile-gradient" />
                    <RecentSearchTileImg
                        src={imageUrl}
                        alt={imageAlt}
                        inventoryCount={inventoryCount}
                        handleOnLoad={() => setImgLoaded(true)}
                    />
                </a>
            </figure>
            <TileKebabMenu category={title}>{buildTileMenuItems()}</TileKebabMenu>
            <TileKebabMenuItemSnackbar
                icon={Icons.share}
                isOpen={isShareSnackbarOpen}
                textLabel="Link copied to your clipboard"
                cancelAction={() => setIsShareSnackbarOpen(false)}
            />
        </div>
    );
};

export default RecentSearchTile;
