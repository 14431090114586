import { useEffect } from 'react';
import { HeroTheme } from '../../utilities/constants';
import HeroFeaturedContent from '../../hero/HeroFeaturedContent';
import { ITrackingPayload } from '../../../../types/analytics';
import { trackEDDL, ANALYTICS_CONSTANTS } from '../../../../utilities/analytics';

function disableHomebasePageStyles() {
    document.body.classList.remove('is-return-visit');

    const headerEl = document.getElementById('header');
    if (headerEl) {
        headerEl.classList.remove('transparent-light');
        headerEl.classList.add('transparent-dark');
    }
}

function FirstTimeHero() {
    useEffect(() => {
        disableHomebasePageStyles();
        setTimeout(function () {
            const payload: ITrackingPayload = {
                event: ANALYTICS_CONSTANTS.EVENTS.PAGE.STATE,
                pageState: {
                    pageState: 'hp-HeroBrandMessage',
                },
            };
            trackEDDL(payload);

            if (window.kmxExperiments.experimentName === 'Cold-Start') {
                const pageStatePayload: ITrackingPayload = {
                    event: ANALYTICS_CONSTANTS.EVENTS.PAGE.STATE,
                    pageState: {
                        pageState: 'Cold-Start:Control',
                    },
                };
                trackEDDL(pageStatePayload);
            }
        }, ANALYTICS_CONSTANTS.PAGE_STATE_LOAD_TIME);
    }, []);

    return (
        <section
            id="first-time-visitor-hero"
            data-fs-section={`HP: Hero`}
            className="first-time-visitor-hero  mdc-layout-grid"
        >
            <div className="first-time-visitor-hero--max-width-container">
                <picture>
                    <source media="(max-width: 767px)" srcSet="/home/images/home/hero/lycg-mobile.webp" />
                    <source
                        media="(min-width: 767.1px) and (max-width: 1151px)"
                        srcSet="/home/images/home/hero/lycg-tablet.webp"
                    />
                    <source media="(min-width: 1151.1px)" srcSet="/home/images/home/hero/lycg-xl.webp" />
                    <img
                        className="first-time-visitor-hero-img"
                        src="/home/images/home/hero/lycg-xl.webp"
                        alt="Parent and child sitting in the rear of their vehicle putting on skates"
                        width="4366"
                        height="1522"
                    />
                </picture>
                <div className="first-time-visitor-hero--grid-container mdc-layout-grid__inner">
                    <HeroFeaturedContent heroTheme={HeroTheme.Dark} ctaLabel="Search Cars" />
                </div>
            </div>
        </section>
    );
}

export default FirstTimeHero;
