import { FC, useState } from 'react';
import * as analytics from '../../../utilities/analytics';
import CarTile from '@kmx/shared-components/car-tile/CarTile';
import Carousel from '../home-base-hero-carousel/Carousel';
import { IFBSDecision } from '../../../api/types/finance';
import { shiftUnavailableVehiclesToEnd } from '../../../pages/home/utilities/helpers';
import { IVehicleTile } from '../../../types/vehicleTile';
import { MiniHomebaseSlideCTA } from '../../../pages/buying-online/components/MiniHomebaseSlideCTA';
import { mapVehicleTileToCar } from '../../../pages/home/hero/car-tile/carTileUtils';
import { buildStatusText, vehicleIsUnavailable } from '../../../utilities/vehicleTileBuilder';
import { IGetCarImagesResponse } from '../../../types/api/getCarImagesResponse';
import Icons from '../../../utilities/icons';
import TileKebabMenuItemSnackbar from '../../../pages/home/hero/tile-kebab-menu/TileKebabMenuItemSnackbar';
import { ITrackingPayload } from '../../../types/analytics';
import { ANALYTICS_CONSTANTS } from '../../../utilities/analytics';

interface ICarTilesCarouselProps {
    vehicleTileList: IVehicleTile[];
    carouselName: string;
    ariaLabel?: string;
    fbsData?: IFBSDecision[];
    isMiniHomebase?: boolean;
    favoriteVehicles?: number[];
    onFavorite: (stockNumber: number) => void;
    onUnfavorite: (stockNumber: number) => void;
    getCarImages: (
        stockNumber: number,
        successCallback: (response: IGetCarImagesResponse) => void,
        errorCallback: (error: unknown) => void
    ) => void;
    nextFocus: string;
    onRemove?: (vehicle: IVehicleTile) => void;
    enableRemove?: boolean;
}

export const CarTilesCarousel: FC<ICarTilesCarouselProps> = ({
    carouselName,
    ariaLabel,
    vehicleTileList,
    fbsData,
    isMiniHomebase,
    favoriteVehicles,
    onFavorite,
    onUnfavorite,
    onRemove,
    getCarImages,
    nextFocus,
    enableRemove = false,
}) => {
    const [isShareSnackbarOpen, setIsShareSnackbarOpen] = useState<boolean>(false);

    const buildCarouselItems = (enableRemove: boolean) => {
        if (!vehicleTileList?.length) {
            return [];
        }

        const handleShareClick = async (stockNumber: number) => {
            if (navigator.share) {
                navigator
                    .share({
                        title: `${document.title}.`,
                        text: `Check out ${document.title}.`,
                        url: `${window.location.origin}/car/${stockNumber}`,
                    })
                    .then(() => console.log('Successful Car Tile Share'))
                    .catch((error) => console.log('Error Sharing Car Tile', error));
            } else {
                await navigator.clipboard.writeText(`${window.location.origin}/car/${stockNumber}`);
                setIsShareSnackbarOpen(true);
            }
        };

        const getMenuItems = (stockNumber: number, isFavorite: boolean) => {
            return [
                {
                    text: `${isFavorite ? 'Remove from' : 'Add to'} favorites`,
                    icon: Icons.favoriteHeart,
                    onClick: () => {
                        if (isFavorite) {
                            onUnfavorite(stockNumber);
                        } else {
                            onFavorite(stockNumber);
                        }
                    },
                    className: `tile-menu-item--favorite ${isFavorite ? 'saved' : ''}`,
                },
                {
                    text: 'Share',
                    icon: Icons.share,
                    onClick: () => {
                        handleShareClick(stockNumber);
                        const name = 'share';
                        const event = ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA;
                        const payload: ITrackingPayload = {
                            event: event,
                            linkDetails: {
                                name: name,
                                position: String(stockNumber),
                            },
                        };
                        analytics.trackEDDL(payload);
                    },
                    className: 'tile-menu-item--share',
                },
            ];
        };

        const getCriticalMenuItem = (vehicle: IVehicleTile) => {
            return {
                text: 'Remove',
                icon: Icons.remove,
                onClick: () => {
                    onRemove?.(vehicle);
                    const name = 'remove';
                    const event = ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA;
                    const payload: ITrackingPayload = {
                        event: event,
                        linkDetails: {
                            name: name,
                        },
                    };
                    analytics.trackEDDL(payload);
                },
                className: 'tile-menu-item--remove',
            };
        };

        const sortedVehicleList = shiftUnavailableVehiclesToEnd(vehicleTileList);

        const handleOnMenuOpen = (stockNumber: number) => {
            const payload: ITrackingPayload = {
                event: ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA,
                linkDetails: {
                    name: 'three dots',
                    position: String(stockNumber),
                },
            };
            analytics.trackEDDL(payload);
        };

        const carouselItems = sortedVehicleList
            .filter((item: any) => item !== null)
            .map((vehicle: IVehicleTile, index: number) => {
                const { stockNumber } = vehicle;

                return (
                    <div className="carousel__slide" key={`${stockNumber}-slide-${index}`}>
                        <div className="carousel-slide-item">
                            <CarTile
                                key={vehicle.stockNumber}
                                car={mapVehicleTileToCar(vehicle)}
                                disableScrollNav={false}
                                imageUrl={vehicle.imageLink}
                                showFavorites={true}
                                isDisabled={vehicleIsUnavailable(vehicle.statusCode, vehicle.isSaleable)}
                                ribbonStyle="standard"
                                ribbonText={buildStatusText(vehicle)}
                                isFavorite={favoriteVehicles?.includes(vehicle.stockNumber)}
                                menuItems={getMenuItems(vehicle.stockNumber, !!favoriteVehicles?.includes(stockNumber))}
                                onFavorite={() => onFavorite(vehicle.stockNumber)}
                                onUnfavorite={() => onUnfavorite(vehicle.stockNumber)}
                                getCarImages={(
                                    stockNumber = vehicle.stockNumber,
                                    success: (response: IGetCarImagesResponse) => void,
                                    error: (error: unknown) => void
                                ) => getCarImages(stockNumber, success, error)}
                                fbsApplication={vehicle?.fbsData?.fbsDecision}
                                fbsDecision={vehicle?.fbsData?.fbsDecision}
                                downPayment={vehicle?.fbsData?.fbsDecision?.downPayment}
                                recommendationType={vehicle.recommendationType}
                                criticalMenuItem={enableRemove ? getCriticalMenuItem(vehicle) : undefined}
                                onMenuOpen={() => handleOnMenuOpen(stockNumber)}
                            />
                        </div>
                    </div>
                );
            });

        if (isMiniHomebase) {
            const headline = carouselItems.length > 1 ? 'None of these quite right?' : 'Not quite right?';
            const hasFbsData = fbsData && fbsData.length > 0;
            carouselItems.push(<MiniHomebaseSlideCTA headline={headline} hasFbsData={hasFbsData} />);
        }

        return carouselItems;
    };

    return (
        <>
            <div id={carouselName} className="carousel--container">
                <div className={`carousel--grid`}>
                    <Carousel
                        name={carouselName.replace(/carousel/, '')}
                        nextFocus={`#${nextFocus}`}
                        ariaLabel={ariaLabel}
                        leftAligned={true}
                    >
                        {buildCarouselItems(enableRemove)}
                    </Carousel>
                </div>
            </div>

            <TileKebabMenuItemSnackbar
                icon={Icons.share}
                isOpen={isShareSnackbarOpen}
                textLabel="Link copied to your clipboard"
                cancelAction={() => setIsShareSnackbarOpen(false)}
            />
        </>
    );
};
