interface Icons {
    Search: () => JSX.Element;
    MagnifyingGlass: () => JSX.Element;
    Tag: () => JSX.Element;
    Arrow: () => JSX.Element;
}

export const Icons: Icons = {
    Search: () => (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.1443 6.81521C15.8176 7.14187 15.8176 7.66688 16.1443 7.98187L21.3243 13.1619H4.66427C4.20927 13.1619 3.83594 13.5352 3.83594 13.9902C3.83594 14.4452 4.20927 14.8185 4.66427 14.8185H21.3359L16.1559 19.9985C15.8293 20.3252 15.8293 20.8502 16.1559 21.1652C16.4826 21.4802 17.0076 21.4919 17.3226 21.1652L23.9259 14.5619C24.0076 14.4919 24.0659 14.3985 24.1009 14.2935C24.1476 14.1885 24.1709 14.0835 24.1709 13.9785C24.1709 13.8735 24.1476 13.7569 24.1009 13.6635C24.0543 13.5585 23.9959 13.4769 23.9259 13.3952L17.3226 6.79188C16.9959 6.46521 16.4709 6.46521 16.1559 6.79188L16.1443 6.81521Z"
                fill="currentColor"
            />
        </svg>
    ),
    MagnifyingGlass: () => (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.7914 15.8747L13.3814 12.4647C14.4814 11.1539 15.1505 9.47635 15.1505 7.63385C15.1505 3.49052 11.7772 0.117188 7.63385 0.117188C3.49052 0.117188 0.117188 3.49052 0.117188 7.63385C0.117188 11.7772 3.49052 15.1505 7.63385 15.1505C9.47635 15.1505 11.1539 14.4814 12.4647 13.3814L15.8747 16.7914C16.003 16.9197 16.168 16.9839 16.333 16.9839C16.498 16.9839 16.663 16.9197 16.7914 16.7914C17.048 16.5347 17.048 16.1222 16.7914 15.8747ZM1.41885 7.63385C1.41885 4.20552 4.20552 1.41885 7.63385 1.41885C11.0622 1.41885 13.8489 4.20552 13.8489 7.63385C13.8489 11.0622 11.0622 13.8489 7.63385 13.8489C4.20552 13.8489 1.41885 11.0622 1.41885 7.63385Z"
                fill="white"
            />
        </svg>
    ),
    Tag: () => (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.76 0.102917H15.4158V0.09375C16.7817 0.09375 17.9 1.21208 17.9 2.57792V8.23375C17.9 8.90292 17.6433 9.52625 17.1758 9.99375L9.99833 17.1712C9.5125 17.6571 8.88 17.8954 8.23833 17.8954C7.59667 17.8954 6.96417 17.6571 6.47833 17.1712L0.8225 11.5154C-0.149167 10.5529 -0.149167 8.97625 0.8225 8.00458L8 0.827083C8.47667 0.359583 9.1 0.102917 9.76 0.102917ZM16.25 9.07708C16.4792 8.85708 16.5983 8.55458 16.5983 8.24292V2.58708C16.5983 1.93625 16.0667 1.40458 15.4158 1.40458H9.76C9.44833 1.40458 9.14583 1.53292 8.92583 1.75292L1.74833 8.93042C1.51917 9.15042 1.4 9.45292 1.4 9.76458C1.4 10.0763 1.52833 10.3787 1.74833 10.5987L7.40417 16.2546C7.84417 16.7037 8.62333 16.7037 9.0725 16.2546L16.25 9.07708ZM14.4993 4.42033C14.4993 4.92659 14.0889 5.337 13.5827 5.337C13.0764 5.337 12.666 4.92659 12.666 4.42033C12.666 3.91407 13.0764 3.50366 13.5827 3.50366C14.0889 3.50366 14.4993 3.91407 14.4993 4.42033Z"
                fill="white"
            />
        </svg>
    ),
    Arrow: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M13.8391 5.83937C13.5591 6.11937 13.5591 6.56938 13.8391 6.83938L18.2791 11.2794H3.99906C3.60906 11.2794 3.28906 11.5994 3.28906 11.9894C3.28906 12.3794 3.60906 12.6994 3.99906 12.6994H18.2891L13.8491 17.1394C13.5691 17.4194 13.5691 17.8694 13.8491 18.1394C14.1291 18.4094 14.5791 18.4194 14.8491 18.1394L20.5091 12.4794C20.5791 12.4194 20.6291 12.3394 20.6591 12.2494C20.6991 12.1594 20.7191 12.0694 20.7191 11.9794C20.7191 11.8894 20.6991 11.7894 20.6591 11.7094C20.6191 11.6194 20.5691 11.5494 20.5091 11.4794L14.8491 5.81938C14.5691 5.53938 14.1191 5.53938 13.8491 5.81938L13.8391 5.83937Z"
                fill="currentColor"
            />
        </svg>
    ),
};
