import { MENU_DATA_ATTR } from './components/menu/constants';
import StoreStatus from './components/storeHours/storeStatus';
import { getUserEnteredZipCode } from './storage/cookie';
import { getStoreStatusDisplay, isClosed, Store, StoreAvailability } from './types/store';
import * as environment from './utils/environment';
import { determineSeeCarsUrl } from './utils/search';

export const links = {
    appointments: {
        text: 'My appointments',
        href: '/mycarmax/appointments'
    },
    appraisals: {
        text: 'Appraisals',
        href: '/mycarmax/appraisals'
    },
    browseByCategory: {
        text: 'Browse by category',
        href: '/cars'
    },
    faq: {
        text: 'FAQ',
        href: '/faq'
    },
    helpCenter: {
        text: 'FAQ &amp; support',
        href: '/help-center'
    },
    buyingOnline: {
        text: 'Buying online',
        href: '/buying-online'
    },
    makeAPayment: {
        text: 'Make a Payment',
        href: `${environment.getCAFBaseUrl()}/account/summary`
    },
    maxcareService: {
        text: 'Warranties &amp; MaxCare&NoBreak;&#174;',
        href: '/car-buying-process/maxcare-service-plans'
    },
    careers: {
        text: 'Search jobs at CarMax',
        href: 'https://careers.carmax.com/us/en/'
    },
    getAnOnlineOffer: {
        text: 'Get an online offer',
        href: '/sell-my-car'
    },
    getPreQualified: {
        text: 'Get pre-qualified',
        href: '/pre-qual/app?op=global_footer'
    },
    sellHowItWorks: {
        text: 'How it works',
        href: '/sell-my-car#how-it-works'
    },
    financeHowItWorks: {
        text: 'How it works',
        href: '/car-financing'
    },
    findAStore: {
        text: 'Find a store',
        href: '/stores'
    },
    carMaxAutoFinance: {
        text: 'CarMax AutoFinance',
        href:'/car-financing/carmax-auto-finance'
    },
    myCarMax: {
        text: 'MyCarMax',
        href: '/mycarmax/home'
    },
    myOrders: {
        text: 'My orders',
        href: '/checkout'
    },
    profileSettings: {
        text: 'Profile settings',
        href: '/mycarmax/profile-settings'
    },
    register: {
        text: 'Create account',
        href: '/mycarmax/register'
    },
    researchArticles: {
        text: 'Car research &amp; advice',
        href: '/research'
    },
    researchEVGuide: {
        text: 'Guide to EVs',
        href: '/research/electric-vehicle-guide'
    },
    savedCars: {
        text: 'My favorites',
        href: '/mycarmax/saved-cars'
    },
    savedSearches: {
        text: 'My saved searches',
        href: '/mycarmax/saved-searches'
    },
    service: {
        text: 'Service &amp; repairs',
        href: '/service'
    },
    signIn: {
        text: 'Sign in',
        href: `/mycarmax/sign-in?returnUrl=${encodeURIComponent(window.location.href)}`
    },
    signOut: {
        text: 'Sign out',
        href: '/mycarmax/sign-out'
    },
    viewAllInventory: {
        text: 'View all inventory',
        href: '/cars/all'
    },
    watchlist: {
        text: 'Your favorites',
        href: '/watchlist'
    },
    whyCarMax: {
        text: 'Why CarMax',
        href: '/why-carmax'
    }
};

export const icons = {
    buildDropdownIcon: (classes?: string) =>
        `<svg ${
            classes ? `class="${classes}"` : ''
        } viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`,

    buildWarningIcon: (classes?: string) =>
        `<svg ${
            classes ? `class="${classes}"` : ''
        } viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M0 0h24v24H0z" fill="none"></path><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path></svg>`,

    buildAlertInfoIcon: (classes?: string) =>
        `<svg ${
            classes ? `class="${classes}"` : ''
        } width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M0 0h24v24H0z" fill="none"></path><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path></svg>`,

    buildSearchIcon: (classes?: string) =>
        `<svg ${
            classes ? `class="${classes}"` : ''
        } width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`,

    buildHomeIcon: (classes?: string) =>
        `<svg ${
            classes ? `class="${classes}"` : ''
        } viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`,

    buildEventAvailableIcon: (classes?: string) =>
        `<svg ${
            classes ? `class= "${classes}"` : ''
        } viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M0 0h24v24H0z" fill="none"></path><path d="M16.53 11.06L15.47 10l-4.88 4.88-2.12-2.12-1.06 1.06L10.59 17l5.94-5.94zM19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11z"></path></svg>`,

    buildFavoriteOutlineIcon: (classes?: string) =>
        `<svg ${
            classes ? `class= "${classes}"` : ''
        } viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><g ><path  d="M0 0h24v24H0z" fill="none"></path><path  d="M16.5 3A6 6 0 0 0 12 5a6 6 0 0 0-4.5-2A5.5 5.5 0 0 0 2 8.5c0 3.8 3.4 6.9 8.6 11.5l1.4 1.4 1.4-1.4c5.2-4.6 8.6-7.7 8.6-11.5A5.5 5.5 0 0 0 16.5 3zm.1 2A3.3 3.3 0 0 1 20 8.4c0 2.8-3.2 5.8-8 10.2-4.8-4.4-8-7.4-8-10.2A3.3 3.3 0 0 1 7.4 5a3.9 3.9 0 0 1 2.9 1.4l1.7 2 1.7-2a3.9 3.9 0 0 1 3-1.4"></path></g></svg>`,

    buildSearchedForIcon: (classes?: string) =>
        `<svg ${
            classes ? `class= "${classes}"` : ''
        } viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg  aria-hidden="true"><path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/><path d="M17.01 14h-.8l-.27-.27c.98-1.14 1.57-2.61 1.57-4.23 0-3.59-2.91-6.5-6.5-6.5s-6.5 3-6.5 6.5H2l3.84 4 4.16-4H6.51C6.51 7 8.53 5 11.01 5s4.5 2.01 4.5 4.5c0 2.48-2.02 4.5-4.5 4.5-.65 0-1.26-.14-1.82-.38L7.71 15.1c.97.57 2.09.9 3.3.9 1.61 0 3.08-.59 4.22-1.57l.27.27v.79l5.01 4.99L22 19l-4.99-5z"/></svg>`
};

const buildDesktopCareersLinkInnerHTML = (optimizeForSeo: boolean = false) => {
    return `<a
        id="careers-link--desktop"
        tabindex="-1"
        class="header-link"
        data-kmx-analytics="prop5=Header-More"
        href="${links.careers.href}"
        ${optimizeForSeo ? 'rel="nofollow"' : ''}
        >${links.careers.text}</a>`;
};

export const content = {
    CLASSNAMES: {
        hidden: 'hidden',
        visible: 'visible',
        bodyScrollLock: 'body-scroll-lock'
    },
    HEADER: {
        id: 'header',
        navRight: {
            class: 'header-nav-right',
            loggedInClass: 'header-nav-right--logged-in'
        }
    },
    FOOTER: {
        id: 'footer'
    },
    FOOTER_COPYRIGHT: {
        id: 'footer-copyright-year'
    },
    MOBILE_NAV: {
        body: {
            class: 'header-drawer-body'
        },
        menuId: 'header-nav-menu',
        menuClass: 'header-drawer',
        drawer: {
            defaultClass: 'header-drawer',
            openClass: 'header-drawer--open'
        },
        focusStartId: 'header-drawer-focus-start'
    },
    MORE_LINKS: {
        sectionClass: 'header-helpful-links-section',
        button: {
            id: 'header-helpful-links-button',
            icon: {
                class: 'header-helpful-links-button-icon',
                openClass: 'header-helpful-links-button-icon--open'
            }
        },
        menuId: 'header-helpful-links-menu',
        defaultMenu: {
            buildInnerHTML: (optimizeForSeo: boolean = false) => {
                return `<ul class="header-footer-menu-list" role="menu" tabindex="-1" aria-orientation="vertical">\
                    <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" data-kmx-analytics="prop5=Header-More" class="header-link" href="${
                        links.service.href
                    }">${links.service.text}</a></li>\
                    <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" data-kmx-analytics="prop5=Header-More" class="header-link" href="${
                        links.helpCenter.href
                    }" ${optimizeForSeo ? 'rel="nofollow"' : ''}>${links.helpCenter.text}</a></li>\
                    <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" data-kmx-analytics="prop5=Header-More" class="header-link" href="${
                        links.whyCarMax.href
                    }" ${optimizeForSeo ? 'rel="nofollow"' : ''}>${links.whyCarMax.text}</a></li>\
                    <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" data-kmx-analytics="prop5=Header-More" class="header-link" href="${
                        links.buyingOnline.href
                    }" ${optimizeForSeo ? 'rel="nofollow"' : ''}>${links.buyingOnline.text}</a></li>\
                    <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" data-kmx-analytics="prop5=Header-More" class="header-link" href="${
                        links.researchArticles.href
                    }">${links.researchArticles.text}</a></li>\
                    <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" data-kmx-analytics="prop5=Header-More" class="header-link" href="${
                        links.researchEVGuide.href
                    }">${links.researchEVGuide.text}</a></li>\
                    <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" data-kmx-analytics="prop5=Header-More" class="header-link" href="${
                        links.maxcareService.href
                    }" ${optimizeForSeo ? 'rel="nofollow"' : ''}>${links.maxcareService.text}</a></li>\
                    <hr class="header-footer-menu-divider" />\
                    <li class="header-footer-menu-list-item header-footer-menu-list-item__delineated-link header-link__careers-link mobile-hidden" role="menuitem" tabindex="-1">${buildDesktopCareersLinkInnerHTML(
                        optimizeForSeo
                    )}</li>\
                    </ul>`;
            }
        },
        careersLink: {
            label: 'Search jobs at CarMax',
            href: 'https://careers.carmax.com/us/en/',
            mobile: {
                linkHTML:
                    '<a id="careers-link--mobile" class="header-link header-link__delineated header-link__careers-link desktop-hidden" data-kmx-analytics="prop5=Header-More" href="https://careers.carmax.com/us/en/">Search jobs at CarMax</a>',
                id: 'careers-link--mobile',
                class: 'header-link header-link__delineated desktop-hidden header-link__careers-link'
            },
            desktop: {
                innerHTML: buildDesktopCareersLinkInnerHTML(),
                id: 'careers-link--desktop',
                class:
                    'header-footer-menu-list-item header-footer-menu-list-item__delineated-link header-link__careers-link mobile-hidden tablet-hidden'
            }
        }
    },
    HEADER_SEARCH: {
        id: 'header-search-bar',
        activeClass: 'header-search-bar--active',
        input: {
            id: 'header-inventory-search'
        },
        menuId: 'header-search-bar-menu',
        formId: 'header-search-bar-form',
        autocompleteList: {
            id: 'header-search-bar-list',
            listItemClass: 'header-footer-menu-list-item',
            listItemRole: 'menuitem',
            listRole: 'menu',
            linkClass: 'menu-item-link',
            textClass: 'menu-item-text'
        }
    },
    HEADER_MY_STORE: {
        sectionClassNoStore: 'header-my-store-section-no-store',
        sectionClass: 'header-my-store-section',
        buttonId: 'header-my-store-button',
        buttonAlertClass: 'header-my-store-button-alert',
        buttonTextId: 'header-my-store-button-text',
        buttonSecondaryTextId: 'header-my-store-button-zipCode',
        storeStatusId: 'header-store-status-text',
        storeStatusTextId: 'store-status--text',
        storeStatusHoursId: 'store-status--status-hours',
        iconClass: 'header-footer-icon',
        menuId: 'header-my-store-menu',
        alertInfoIconContainerClass: 'header-alert-info-icon-container',
        alertInfoIconClass: 'header-alert-info-icon mobile-hidden',
        locationAlertIconContainerClass: 'header-footer-location-icon-alert',
        locationAlertCircleClass: 'header-footer-location-icon-alert-circle',
        storeSearchFormId: 'header-store-search-form'
    },
    FOOTER_MY_STORE: {
        sectionClass: 'footer-my-store-section',
        buttonId: 'footer-my-store-button',
        buttonAlertClass: 'footer-my-store-button-alert',
        buttonTextId: 'footer-my-store-button-text',
        iconClass: 'header-footer-icon',
        menuId: 'footer-my-store-menu',
        locationAlertIconContainerClass: 'header-footer-location-icon-alert',
        locationAlertCircleClass: 'header-footer-location-icon-alert-circle',
        storeSearchFormId: 'footer-store-search-form'
    },
    HEADER_FOOTER_FLYOUT_MENU: {
        storeHoursMenuClass: 'header-footer-my-store-details-store-hours-menu',
        storeHoursButtonClass: 'header-footer-my-store-details-store-hours-button'
    },
    MY_STORE_DETAILS: {
        addressClass: 'header-footer-my-store-details-address-link',
        getInnerHtml: (store: Store, availability: StoreAvailability, type: 'header' | 'footer') => {
            return getStoreDetailsHTML() + getStoreSearchHTML();
            function getStoreDetailsHTML() {
                const AddressLine2 = `${store.MailingCity}, ${store.StateAbbreviation} ${store.ZipCode}`;
                const storeStatus = new StoreStatus(store);
                const storeDistance = store.DistanceInMiles;

                const availabilityText = availability.IsOpen ? "Currently Open" : "Currently Closed";
                const alertMessage = availability.Alert?.message; // Nullable object
                const alertStatus = availability.Alert?.status;
                const alertStatusClosure = alertStatus == 'Closed' || isClosed(alertMessage);
                let statusText = "";

                // Order: Alert (abnormality) -> Holiday -> Regular open/closed
                if(alertMessage) {
                    statusText = getStoreStatusDisplay(alertMessage);
                }else if(storeStatus.statusText) {
                    statusText = storeStatus.statusText;
                }else {
                    statusText = availabilityText;
                }

                const seeCarsUrl = determineSeeCarsUrl(store);
                const zipCodeToDisplay = getUserEnteredZipCode() ?? store.ZipCode;

                let html = store.Id
                    ? `<div id="${type}-my-store-details" class="header-footer-my-store-details"><div class="header-footer-my-store-details-header">Your store for ${
                          zipCodeToDisplay
                      }:</div>\
                <div class="header-footer-my-store-details-store-name"><span>${
                    store.Name
                }<span aria-label="${storeDistance + " miles"}" class="header-footer-my-store-details-distance">${
                          storeDistance > 0 ? '(' + storeDistance + ' mi)' : ''
                      }</span></span><span></span></div>\
                <a id="my-store-details-btn" tabindex="0" data-kmx-analytics="prop5=Header-Location" ${MENU_DATA_ATTR.dialog}="${
                          MENU_DATA_ATTR.focusStart

                      }" class="header-footer-my-store-details-link" href="/stores/${
                          store.Id
                      }">View more store details</a>\
                <div class="header-footer-my-store-details-info-section">\
                    <div class="header-footer-my-store-details-address">${store.AddressLine1}<br>${AddressLine2}</div>\
                    ${`<button class="header-footer-my-store-details-store-hours-button" data-kmx-analytics="prop5=Header-Location">${
                        alertStatusClosure
                            ? `<div class="icon-wrapper">${icons.buildWarningIcon('warningIcon')}</div>`
                            : ''
                    }
                        <span class="store-status-text ${statusText.toLowerCase()}">${
                        statusText
                    }
                    ${alertStatusClosure ? "":    `</span><span class="store-status-hours">${storeStatus.hoursText}`}
                        </span> <span class="icon-wrapper">${icons.buildDropdownIcon('dropdownIcon')}</span></button>`}
                </div>\
                <div class="${content.HEADER_FOOTER_FLYOUT_MENU.storeHoursMenuClass}">${storeStatus.storeMenu}</div>
                <a rel="nofollow" tabindex="0" class="header-footer-my-store-details-inventory-link" data-kmx-analytics="prop5=Header-Location | searchInit" href="${seeCarsUrl}">
                    See cars at this store
                </a>\
                </div>`
                    : '';
                return html;
            }

            function getStoreSearchHTML() {
                const STORE_SEARCH_LABEL = 'Change your store';
                const STORE_SEARCH_PLACEHOLDER = 'Search by ZIP or State';
                let html = `<div class="header-footer-store-search-bar">\
                    <form class="header-footer-store-search-bar-form" role="search" id="${type}-store-search-form" onSubmit="event.preventDefault()">\
                        <label for="${type}-store-search" >${STORE_SEARCH_LABEL}</label>\
                        <div class="header-footer-store-search-bar-container">\
                            <input tabindex="0" ${
                                store.Id ? '' : `${MENU_DATA_ATTR.dialog}="${MENU_DATA_ATTR.focusStart}"`
                            } id="${type}-store-search-form-input" name="keyword" class="header-footer-store-search-bar-input" type="search" placeholder="${STORE_SEARCH_PLACEHOLDER}" autocomplete="off" value="" />\
                            <div class="header-search-bar-button-container">\
                                <button data-kmx-analytics="prop5=Header-Location" tabindex="0" ${MENU_DATA_ATTR.dialog}="${
                    MENU_DATA_ATTR.focusEnd
                }" id="${type}-store-search-button" aria-label="Search for CarMax stores." type="submit" class="header-footer-icon-button">\
                                    ${icons.buildSearchIcon()}\
                                </button>\
                            </div>\
                        </div>\
                    </form>\
                </div>`;
                return html;
            }
        }
    },
    MY_PROFILE: {
        sectionClass: 'header-my-profile-section',
        buttonId: 'header-my-profile-button',
        menuId: 'header-my-profile-menu',
        authenticatedMenuWithHub: {
            innerHTML: `<ul class="header-footer-menu-list" role="menu" tabindex="-1" aria-hidden="true" aria-orientation="vertical">\
                <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a rel="nofollow" class="payment-link" data-kmx-analytics="prop5=Header-Profile" href="${
                    links.makeAPayment.href
                }">${links.makeAPayment.text}</a></li>\
                <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" class="header-link" data-kmx-analytics="prop5=Header-Profile"href="${
                    links.myCarMax.href
                }"><span class="icon-wrapper">${icons.buildHomeIcon('homeIcon')}</span>${links.myCarMax.text}</a></li>\
                <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" class="header-link" data-kmx-analytics="prop5=Header-Profile" href="${
                    links.appointments.href
                }"><span class="icon-wrapper">${icons.buildEventAvailableIcon('homeIcon')}</span>${
                links.appointments.text
            }</a></li>\
                <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" class="header-link header-link--favorites" data-kmx-analytics="prop5=Header-Profile" href="${
                    links.savedCars.href
                }"><span class="icon-wrapper">${icons.buildFavoriteOutlineIcon('homeIcon')}</span>${
                links.savedCars.text
            }</a></li>\
                <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" class="header-link" data-kmx-analytics="prop5=Header-Profile" href="${
                    links.savedSearches.href
                }"><span class="icon-wrapper">${icons.buildSearchedForIcon('homeIcon')}</span>${
                links.savedSearches.text
            }</a></li>\
                <hr class="header-footer-menu-divider" />\
                <li class="header-footer-menu-list-item header-footer-menu-list-item__delineated-link" role="menuitem" tabindex="-1"><div class="header-footer-menu-list-item"><a tabindex="-1" class="header-link" data-kmx-analytics="prop5=Header-Profile" href="${
                    links.myOrders.href
                }">${links.myOrders.text}</a></div></li>\
                <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" class="header-link" data-kmx-analytics="prop5=Header-Profile" href="${
                    links.profileSettings.href
                }">${links.profileSettings.text}</a></li>\
                <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" class="header-link header-link--priority" data-kmx-analytics="prop5=Header-Profile" href="${
                    links.signOut.href
                }">${links.signOut.text}</a></li>\
            </ul>`
        },
        authenticatedMenu: {
            innerHTML: `<ul class="header-footer-menu-list" role="menu" tabindex="-1" aria-hidden="true" aria-orientation="vertical">\
                <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a rel="nofollow" class="payment-link" data-kmx-analytics="prop5=Header-Profile" href="${
                    links.makeAPayment.href
                }">${links.makeAPayment.text}</a></li>\
                <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" class="header-link" data-kmx-analytics="prop5=Header-Profile" href="${
                    links.myCarMax.href
                }"><span class="icon-wrapper">${icons.buildHomeIcon('homeIcon')}</span>${links.myCarMax.text}</a></li>\
                <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" class="header-link" data-kmx-analytics="prop5=Header-Profile" href="${
                    links.appointments.href
                }"><span class="icon-wrapper">${icons.buildEventAvailableIcon('homeIcon')}</span>${
                links.appointments.text
            }</a></li>\
                <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" class="header-link header-link--favorites" data-kmx-analytics="prop5=Header-Profile" href="${
                    links.savedCars.href
                }"><span class="icon-wrapper">${icons.buildFavoriteOutlineIcon('homeIcon')}</span>${
                links.savedCars.text
            }</a></li>\
                <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" class="header-link" data-kmx-analytics="prop5=Header-Profile" href="${
                    links.savedSearches.href
                }"><span class="icon-wrapper">${icons.buildSearchedForIcon('homeIcon')}</span>${
                links.savedSearches.text
            }</a></li>\
                <hr class="header-footer-menu-divider" />\
                <li class="header-footer-menu-list-item header-footer-menu-list-item__delineated-link" role="menuitem" tabindex="-1"><div class="header-footer-menu-list-item"><a tabindex="-1" class="header-link" data-kmx-analytics="prop5=Header-Profile" href="${
                    links.profileSettings.href
                }">${links.profileSettings.text}</a></div></li>\
                <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" class="header-link header-link--priority" data-kmx-analytics="prop5=Header-Profile" href="${
                    links.signOut.href
                }">${links.signOut.text}</a></li>\
            </ul>`
        },
        unauthenticatedMenu: {
            innerHTML: `<ul class="header-footer-menu-list" role="menu" tabindex="-1" aria-hidden="true" aria-orientation="vertical">\
                <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a rel="nofollow" class="payment-link" data-kmx-analytics="prop5=Header-Profile" href="${links.makeAPayment.href}">${links.makeAPayment.text}</a></li>\
                <li class="header-footer-menu-list-item header-footer-menu-list-item__delineated-link" role="menuitem" tabindex="-1"><div class="header-footer-menu-list-item"><a tabindex="-1" class="header-link" data-kmx-analytics="prop5=Header-Profile" href="${links.signIn.href}">${links.signIn.text}</a></div></li>\
                <li class="header-footer-menu-list-item" role="menuitem" tabindex="-1"><a tabindex="-1" class="header-link" data-kmx-analytics="prop5=Header-Profile" href="${links.register.href}">${links.register.text}</a></li>\
            </ul>`
        }
    },
    SAVED_CARS_BUTTON: {
        id: 'header-saved-cars-button',
        visibleClass: 'visible'
    },
    STORE_CHOOSER: {
        WRAPPER: {
            id: 'store-chooser-wrapper',
            class: 'store-chooser-wrapper',
            visibleClass: 'visible'
        },
        BACKGROUND: {
            class: 'store-chooser-background-modal'
        },
        MODAL: {
            id: 'store-chooser-modal',
            class: 'store-chooser-modal',
            role: 'dialog',
            HEADER: {
                id: 'store-chooser-modal_header',
                class: 'store-chooser-modal_header',
                TITLE: {
                    class: 'store-chooser-modal_header-title',
                    id: 'store-chooser-modal_header-title',
                    innerText: 'Select your store'
                },
                DESCRIPTION: {
                    class: 'hidden',
                    id: 'store-chooser-modal_header-description',
                    innerText:
                        'You can change your store in this dialog and have the carmax experience update accordingly.'
                },
                CLOSE: {
                    class: 'store-chooser-modal_header-close',
                    type: 'button',
                    ariaLabel: 'close',
                    ariaHidden: 'false',
                    innerHTML:
                        '<svg class="store-chooser-modal_header-close-icon-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>'
                }
            },
            FORM: {
                id: 'store-chooser-keyword-search-form',
                class: 'store-chooser-modal_search-form',
                visibleClass: 'visible',
                INPUT: {
                    id: 'store-chooser-keyword-input',
                    class: 'store-chooser-modal_search-form-input',
                    name: 'keyword',
                    placeholder: 'Search by ZIP or State'
                },
                BUTTON: {
                    class: 'store-chooser-modal_search-form-button',
                    innerHTML:
                        '<svg class="store-chooser-modal_search-form-button-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 10-.7.7l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0a4.5 4.5 0 11-.01-8.99A4.5 4.5 0 019.5 14z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>',
                    type: 'submit',
                    ariaLabel: 'Set My Store',
                    ariaHidden: 'false'
                }
            },
            BODY: {
                id: 'store-chooser-modal-body',
                class: 'store-chooser-modal-body',
                loadingInnerHTML: `<div class="carmax-loading"><div class="carmax-loading-ring first-ring"></div><div class="carmax-loading-ring second-ring"></div><div class="carmax-loading-ring third-ring"></div><div class="carmax-loading-ring fourth-ring"></div></div>`,
                noMatchesFoundInnerHtml: `<div class="store-chooser-modal_no-matches"><p class="store-chooser-modal_no-matches-text">No matches found</p></div>`,
                LIST: {
                    class: 'store-chooser-modal_list',
                    STORE: {
                        class: 'store-chooser-modal_list-store',
                        itemInnerHTML: (
                            name: string,
                            distance: number,
                            street: string,
                            city: string,
                            state: string,
                            zip: string,
                            alertMessage: string | null,
                            hoursText: string,
                            showHours: boolean
                        ) => {
                            return `<div class='store-chooser-modal_list-store-info'>
                                        <div class='store-title'>
                                                <h4 class='store-title-name'>${name}</h4>
                                                ${
                                                    distance > 0
                                                        ? `<span aria-label="${distance + " miles"}" class='store-title-distance'>~${distance} mi</span>`
                                                        : ''
                                                }
                                        </div>
                                        <p class='store-address'>${street}<br>${city}, ${state} ${zip}</p>
                                        ${
                                            alertMessage
                                                ? `<p class='store-hours alert'>${icons.buildWarningIcon(
                                                      'warningIcon'
                                                  )}${alertMessage}</p>`
                                                : showHours
                                                ? `<p class='store-hours'>${hoursText}</p>`
                                                : ``
                                        }
                                    </div>`;
                        },
                        selectClass: 'store-chooser-modal_list-store-select',
                        selectGrandOpenClass:
                            'store-chooser-modal_list-store-select-grand-open',
                        selectGrandOpenInnerHTML: `<svg class="grand-open-store-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true"><path d="M23 8c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1s1 .4 1 1v6c0 .6-.4 1-1 1zM19 9c-.3 0-.5-.1-.7-.3l-4-4c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l4 4c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3zM19 13h-6c-.6 0-1-.4-1-1s.4-1 1-1h6c.6 0 1 .4 1 1s-.4 1-1 1z" fill="#db187a" /></svg>Opening Soon`,
                        selectSelectedClass:
                            'store-chooser-modal_list-store-select-selected',
                        selectSelectedInnerHTML: `<svg class="selected-store-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M0 0h24v24H0z" fill="none"></path><path d="M12 2a10 10 0 100 20 10 10 0 000-20zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg>Store Selected`,
                        selectButtonClass:
                            'store-chooser-modal_list-store-select-button kmx-button kmx-button--tertiary',
                        selectButtonInnerText: 'Set as my store',
                        selectButtonType: 'button',
                        selectButtonAriaLabel: 'set store',
                        selectButtonAriaHidden: 'false',
                        allStoreLinkClass: 'store-chooser-modal_list-store-all-link',
                        allStoreLinkText: 'See All Stores',
                        allStoreLinkHref: '/stores'
                    }
                },
                ERROR: {
                    class: 'store-chooser-modal_error',
                    innerHTML: `<svg class="store-chooser-modal_error-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M0 0h24v24H0z" fill="none"></path><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path>
                        </svg>
                        <div class="store-chooser-modal_error-text">
                            Try searching again<br>by ZIP or State
                        </div>`
                }
            }
        },
        EVENT_NAMES: {
            headerKeywordInput: 'store-chooser-header-input',
            footerKeywordInput: 'store-chooser-footer-input'
        }
    },
    OPTIMIZED_SEARCH: {
        SUV : {
            listItemUrl :'/cars/sport-utilities',
            listItemText : 'SUVs'
        },
        Hybrid : {
            listItemUrl :'/cars/plug-in-hybrid/electric/hybrid',
            listItemText : 'Hybrids & EVs'
        },
        Truck : {
            listItemUrl :'/cars/pickup-trucks',
            listItemText : 'Trucks'
        },
        Vans : {
            listItemUrl :'/cars/minivans-and-vans',
            listItemText : 'Vans & Minivans'
        },
        Article : {
            listItemUrl :'/articles/most-reliable-cars',
            listItemText : 'Most Reliable Cars — Article'
        }
    },
};
