import axios, { AxiosError, AxiosResponse, Method } from 'axios';
import { icoTests } from '../context/features';
import { v4 as uuidv4 } from 'uuid';
import { parseJwt } from '../utils/token';
import { genRandomHexString } from '../utils/randomHexString';

const baseURL = process.env.API_URL;
// const baseURL = 'https://localhost:5101/';
const bufferInMs = 30000;
const correlationId = uuidv4();

interface IToken {
    exp: number;
}

let traceParentId: string = null;

const generateTraceParentId = (): string => {
    if (!traceParentId) {
        traceParentId = `00-${genRandomHexString(32)}-${genRandomHexString(16)}-01`;
    }
    return traceParentId;
};

export const tannhauserRequest = async (
    method: Method,
    methodUrl: string,
    params = {},
    data = {},
    isBlob = false
): Promise<AxiosResponse> => {
    const token = parseJwt(process.env['TOKEN']) as IToken;
    if (!token || Date.now() >= token.exp * 1000 - bufferInMs) {
        await getSetToken();
        return await createTannhauserRequest(method, methodUrl, params, data, isBlob);
    } else {
        try {
            return await createTannhauserRequest(method, methodUrl, params, data, isBlob);
        } catch (error) {
            if (error.response && error.response.status == 401) {
                return getSetToken().then(() => createTannhauserRequest(method, methodUrl, params, data, isBlob));
            } else {
                return null;
            }
        }
    }
};

export const request = (
    method: Method,
    methodUrl: string,
    params = {},
    data = {},
    isBlob = false
): Promise<AxiosResponse> => {
    const token = parseJwt(process.env['TOKEN']) as IToken;
    if (!token || Date.now() >= token.exp * 1000 - bufferInMs) {
        return getSetToken().then(() => createRequest(method, methodUrl, params, data, isBlob));
    } else {
        return createRequest(method, methodUrl, params, data, isBlob).catch((error: AxiosError) => {
            if (error.response && error.response.status == 401) {
                return getSetToken().then(() => createRequest(method, methodUrl, params, data, isBlob));
            } else {
                return null;
            }
        });
    }
};

export const requestV2 = (
    method: Method,
    methodUrl: string,
    params = {},
    data = {},
    isBlob = false
): Promise<AxiosResponse> => {
    const token = parseJwt(process.env['TOKEN']) as IToken;
    if (!token || Date.now() >= token.exp * 1000 - bufferInMs) {
        return getSetToken().then(() => createRequest(method, methodUrl, params, data));
    } else {
        return createRequest(method, methodUrl, params, data, isBlob).catch((error: AxiosError) => {
            if (error?.response?.status == 401) {
                return getSetToken().then(() => createRequest(method, methodUrl, params, data));
            } else {
                return error?.response;
            }
        });
    }
};

const createTannhauserRequest = (
    method: Method,
    methodUrl: string,
    params = {},
    data = {},
    isBlob = false
): Promise<AxiosResponse> => {
    return axios({
        method: method,
        url: process.env.AG_URL + methodUrl,
        headers: {
            Authorization: 'Bearer ' + process.env.TOKEN,
            IcoExperiments: icoTests,
            KR: process.env.BUILD_CODE,
            CorrelationId: correlationId,
            traceparent: generateTraceParentId(),
        },
        responseType: isBlob ? 'blob' : 'json',
        params: {
            ...params,
        },
        data: {
            ...data,
        },
    });
};
interface ITokenBody {
    token: string;
}

const createRequest = (
    method: Method,
    methodUrl: string,
    params = {},
    data = {},
    isBlob = false
): Promise<AxiosResponse> => {
    return axios({
        method: method,
        url: baseURL + methodUrl,
        headers: {
            Authorization: 'Bearer ' + process.env.TOKEN,
            IcoExperiments: icoTests,
            KR: process.env.BUILD_CODE,
            CorrelationId: correlationId,
            traceparent: generateTraceParentId(),
        },
        responseType: isBlob ? 'blob' : 'json',
        params: {
            ...params,
        },
        data: {
            ...data,
        },
    });
};
interface ITokenBody {
    token: string;
}

const getSetToken = (): Promise<string> => {
    return process.env.AUTH_REFRESH_HANDLER().then((token: string | ITokenBody) => {
        if (typeof token === 'string') {
            process.env['TOKEN'] = token;
            return token;
        } else {
            process.env['TOKEN'] = token?.token;
            return token.token;
        }
    });
};
