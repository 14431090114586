import { INav } from './types';
import { icons } from "./icons";
import { getCAFBaseUrl } from '../../utils/environment';
import { getStoreId } from "../../storage/cookie";
import { ANALYTICS_CONSTANTS } from '../../utils/analytics';

// kmxAnalytics is optional as we know that the top header items require prop5= Header,
// which is baked into the thumb-nav-item.ts button. Additional tags can be added to the menu data here.
export const data: INav = {
    title: 'Main Nav',
    menuItems: [
        {
            icon: icons.magnifyingGlass,
            title: 'Shop',
            submenu: [
                {
                    icon: icons.categories,
                    text: 'Browse by category',
                    kmxAnalytics: 'prop5=Header-Shop|searchInit',
                    url: '/cars',
                    analytics: {
                        "event" : ANALYTICS_CONSTANTS.EVENTS.searchInitiated,
                        "searchInitiation": {
                            "initiationMethod": 'home|browse-by-category|Header-Shop'
                        }
                    }
                },
                {
                    icon: icons.searchList,
                    text: 'View all inventory',
                    kmxAnalytics: 'prop5=Header-Shop|searchInit',
                    url: '/cars/all',
                    analytics: {
                        "event" : ANALYTICS_CONSTANTS.EVENTS.searchInitiated,
                        "searchInitiation": {
                            "initiationMethod": 'home|view all inventory|Header-Shop'
                        }
                    }
                },
                {
                    icon: icons.location,
                    text: 'Shop cars near me',
                    kmxAnalytics: 'prop5=Header-Shop|searchInit',
                    url: `/cars/${getStoreId()}`,
                    analytics: {
                        "event" : ANALYTICS_CONSTANTS.EVENTS.searchInitiated,
                        "searchInitiation": {
                            "initiationMethod": 'home|shop cars near me|Header-Shop'
                        }
                    }
                },
                {
                    icon: icons.buildings,
                    text: 'Find a store',
                    kmxAnalytics: 'prop5=Header-Shop',
                    url: '/stores',
                }
            ]
        },
        {
            icon: icons.tag,
            title: 'Sell/Trade',
            analytics: {
                "linkDetail" : {
                    "name": 'Sell'
                }
            },
            submenu: [
                {
                    icon: icons.offer,
                    text: 'Get an online offer',
                    kmxAnalytics: 'prop5=Header-Sell',
                    url: '/sell-my-car',
                },
                {
                    icon: icons.question,
                    text: 'How it works',
                    kmxAnalytics: 'prop5=Header-Sell',
                    url: '/sell-my-car#how-it-works',
                }
            ]
        },
        {
            icon: icons.dollarSignSmaller,
            title: 'Finance',
            submenu: [
                {
                    icon: icons.qualified,
                    text: 'Get pre-qualified',
                    kmxAnalytics: 'noClickTrack | prop5= Header-Finance',
                    url: '/pre-qual/app?op=exposed_nav_header',
                    attributes: {
                        "data-finance-lead-initiate": "true",
                        "data-finance-op": "Header_Finance",
                        "data-finance-formtype": "Finance First Prequalification Application",
                        "data-finance-action": "Init",
                        "data-ignore-anchor-tag-rule": "true",
                    },
                    analytics: {
                        "event": ANALYTICS_CONSTANTS.EVENTS.getPreQualified,
                        "leadDetails": {
                            "leadOriginationPage": 'Header_GetPrequalified',
                            "leadType": 'Finance First Prequalification Application',
                        }
                    }
                },
                {
                    icon: icons.question,
                    text: 'How it works',
                    kmxAnalytics: 'prop5=Header-Finance',
                    url: '/car-financing',
                },
                {
                    icon: icons.money,
                    text: 'CarMax Auto Finance',
                    kmxAnalytics: 'prop5=Header-Finance',
                    url: '/car-financing/carmax-auto-finance',
                }
            ]
        },
        {
            icon: icons.threeDots,
            title: 'More',
            submenu: [
                {
                    icon: icons.service,
                    text: 'Service &amp; repairs',
                    kmxAnalytics: 'prop5=Header-More',
                    url: '/service',
                },
                {
                    icon: icons.lifePreserver,
                    text: 'FAQs &amp; support',
                    kmxAnalytics: 'prop5=Header-More',
                    url: '/help-center',
                },
                {
                    icon: icons.diamond,
                    text: 'Why CarMax',
                    kmxAnalytics: 'prop5=Header-More',
                    url: '/why-carmax',
                },
                {
                    text: 'Buying online',
                    kmxAnalytics: 'prop5=Header-More',
                    url: '/buying-online',
                },
                {
                    text: 'Car research &amp; advice',
                    kmxAnalytics: 'prop5=Header-More',
                    url: '/research',
                },
                {
                    text: 'Guide to EVs',
                    kmxAnalytics: 'prop5=Header-More',
                    url: '/research/electric-vehicle-guide',
                },
                {
                    text: 'Warranties &amp; MaxCare⁠&reg;',
                    kmxAnalytics: 'prop5=Header-More',
                    url: '/car-buying-process/maxcare-service-plans',
                },
                {
                    text: 'Search jobs at CarMax',
                    kmxAnalytics: 'prop5=Header-More',
                    url: 'https://careers.carmax.com/us/en/',
                }


            ]
        },
        {
            icon: icons.accountProfile,
            title: 'My Account',
            isAccountMenu: true,
            submenu: [
                {
                    icon: btoa('<div style="display: block" id="reservation-loader" class="mydashboard-loader"></div>'),
                    text: 'My dashboard',
                    kmxAnalytics: 'prop4=MyCarMax|prop5=Header-Profile-Exposed-Nav',
                    url: '/mycarmax/home',
                    showLoggedIn: true,
                },
                {
                    icon: icons.calendar,
                    text: 'My appointments',
                    kmxAnalytics: 'prop4=My appointments|prop5=Header-Profile-Exposed-Nav',
                    url: '/mycarmax/appointments',
                    showLoggedIn: true,
                },
                {
                    icon: icons.dollarSign,
                    text: 'Make a payment',
                    kmxAnalytics: 'prop4=Make a payment|prop5=Header-Profile-Exposed-Nav',
                    url: `${getCAFBaseUrl()}/account/summary`,
                },
                {
                    text: 'My favorites',
                    kmxAnalytics: 'prop5=Header-Profile-Exposed-Nav',
                    url: '/mycarmax/saved-cars',
                    showLoggedIn: true,
                },
                {
                    text: 'My saved searches',
                    kmxAnalytics: 'prop5=Header-Profile-Exposed-Nav',
                    url: '/mycarmax/saved-searches',
                    showLoggedIn: true,
                },
                {
                    text: 'Profile settings',
                    kmxAnalytics: 'prop5=Header-Profile-Exposed-Nav',
                    url: '/mycarmax/profile-settings',
                    showLoggedIn: true,
                },
                {
                    icon: icons.signIn,
                    text: 'Sign in',
                    kmxAnalytics: 'prop5=Header-Profile-Exposed-Nav',
                    url: `/mycarmax/sign-in?returnUrl=${encodeURIComponent(window.location.href)}`,
                    showLoggedIn: false,
                },
                {
                    text: 'Sign out',
                    kmxAnalytics: 'prop5=Header-Profile-Exposed-Nav',
                    url: '/mycarmax/sign-out',
                    showLoggedIn: true,
                }, {
                    icon: icons.register,
                    text: 'Create account',
                    kmxAnalytics: 'prop5=Header-Profile-Exposed-Nav',
                    url: '/mycarmax/register',
                    showLoggedIn: false,
                },
            ]
        },
    ]
};

export const testData: INav = {
    title: 'Main Nav',
    menuItems: [
        {
            icon: icons.magnifyingGlass,
            title: 'Shop',
            submenu: [
                {
                    icon: icons.location,
                    text: 'Shop cars near me',
                    kmxAnalytics: 'prop5=Header-Shop|searchInit',
                    url: `/cars/${getStoreId()}`,
                    analytics: {
                        "event" : ANALYTICS_CONSTANTS.EVENTS.searchInitiated,
                        "searchInitiation": {
                            "initiationMethod": 'home|shop cars near me|Header-Shop'
                        }
                    }
                },
                {
                    icon: icons.categories,
                    text: 'Browse by category',
                    kmxAnalytics: 'prop5=Header-Shop|searchInit',
                    url: '/cars',
                    analytics: {
                        "event" : ANALYTICS_CONSTANTS.EVENTS.searchInitiated,
                        "searchInitiation": {
                            "initiationMethod": 'home|browse by category|Header-Shop'
                        }
                    }
                },
                {
                    icon: icons.searchList,
                    text: 'View all inventory',
                    kmxAnalytics: 'prop5=Header-Shop|searchInit',
                    url: '/cars/all',
                    analytics: {
                        "event" : ANALYTICS_CONSTANTS.EVENTS.searchInitiated,
                        "searchInitiation": {
                            "initiationMethod": 'home|view-all-inventory|Header-Shop'
                        }
                    }
                },
                {
                    icon: icons.buildings,
                    text: 'Find a store',
                    kmxAnalytics: 'prop5=Header-Shop',
                    url: '/stores',

                },
                {
                    icon: icons.sparkles,
                    text: 'Car research &amp; advice',
                    kmxAnalytics: 'prop3=Homepage | prop4= Car research & advice | prop5=Header-Finance | prop6=prop3|prop4|prop5',
                    url: '/research',

                },
                {
                    text: 'Guide to EVs',
                    kmxAnalytics: 'prop3=Homepage | prop4= Guide to EVs | prop5=Header-Finance | prop6=prop3|prop4|prop5',
                    url: '/research/electric-vehicle-guide',

                }
            ]
        },
        {
            icon: icons.tag,
            title: 'Sell/Trade',
            analytics: {
                "linkDetail" : {
                    "name": 'Sell'
                }
            },
            submenu: [
                {
                    icon: icons.offer,
                    text: 'Get an online offer',
                    kmxAnalytics: 'prop5=Header-Sell',
                    url: '/sell-my-car',
                },
                {
                    icon: icons.question,
                    text: 'How it works',
                    kmxAnalytics: 'prop5=Header-Sell',
                    url: '/sell-my-car#how-it-works',
                }
            ]
        },
        {
            icon: icons.dollarSignSmaller,
            title: 'Finance',
            submenu: [
                {
                    icon: icons.qualified,
                    text: 'Get pre-qualified',
                    kmxAnalytics: 'noClickTrack | prop5= Header-Finance',
                    url: '/pre-qual/app?op=exposed_nav_header',
                    attributes: {
                        "data-finance-lead-initiate": "true",
                        "data-finance-op": "Header_Finance",
                        "data-finance-formtype": "Finance First Prequalification Application",
                        "data-finance-action": "Init",
                        "data-ignore-anchor-tag-rule": "true",
                    },
                    analytics: {
                        "event": ANALYTICS_CONSTANTS.EVENTS.getPreQualified,
                        "leadDetails": {
                            "leadOriginationPage": 'Header_GetPrequalified',
                            "leadType": 'Finance First Prequalification Application',
                        }
                    }
                },
                {
                    icon: icons.question,
                    text: 'How it works',
                    kmxAnalytics: 'prop5=Header-Finance',
                    url: '/car-financing',
                },
                {
                    icon: icons.calculator,
                    text: 'Car payment calculator',
                    kmxAnalytics: 'prop3=Homepage | prop4= car payment calculator | prop5=Header-Finance | prop6=prop3|prop4|prop5',
                    url: '/car-financing/car-payment-calculator',
                },
                {
                    icon: icons.money,
                    text: 'CarMax Auto Finance',
                    kmxAnalytics: 'prop5=Header-Finance',
                    url: '/car-financing/carmax-auto-finance',
                }
            ]
        },
        {
            icon: icons.threeDots,
            title: 'More',
            submenu: [
                {
                    icon: icons.service,
                    text: 'Service &amp; repairs',
                    kmxAnalytics: 'prop5=Header-More',
                    url: '/service',
                },
                {
                    icon: icons.lifePreserver,
                    text: 'FAQs &amp; support',
                    kmxAnalytics: 'prop5=Header-More',
                    url: '/help-center',
                },
                {
                    icon: icons.diamond,
                    text: 'Why CarMax',
                    kmxAnalytics: 'prop5=Header-More',
                    url: '/why-carmax',
                },
                {
                    text: 'Buying online',
                    kmxAnalytics: 'prop5=Header-More',
                    url: '/buying-online',
                },
                {
                    text: 'Warranties &amp; MaxCare⁠&reg;',
                    kmxAnalytics: 'prop5=Header-More',
                    url: '/car-buying-process/maxcare-service-plans',
                },
                {
                    text: 'Search jobs at CarMax',
                    kmxAnalytics: 'prop5=Header-More',
                    url: 'https://careers.carmax.com/us/en/',
                }


            ]
        },
        {
            icon: icons.accountProfileGif,
            title: 'My Account',
            isAccountMenu: true,
            submenu: [
                {
                    icon: icons.dollarSign,
                    text: 'Make a payment',
                    kmxAnalytics: 'prop4=Make a payment|prop5=Header-Profile-Exposed-Nav',
                    url: `${getCAFBaseUrl()}/account/summary`,
                },
                {
                    icon: icons.house,
                    text: 'MyCarMax',
                    kmxAnalytics: 'prop4=MyCarMax|prop5=Header-Profile-Exposed-Nav',
                    url: '/mycarmax/home',
                    showLoggedIn: true,
                },
                {
                    icon: icons.calendar,
                    text: 'My appointments',
                    kmxAnalytics: 'prop4=My appointments|prop5=Header-Profile-Exposed-Nav',
                    url: '/mycarmax/appointments',
                    showLoggedIn: true,
                },
                {
                    text: 'My favorites',
                    kmxAnalytics: 'prop5=Header-Profile-Exposed-Nav',
                    url: '/mycarmax/saved-cars',
                    showLoggedIn: true,
                },
                {
                    text: 'My saved searches',
                    kmxAnalytics: 'prop5=Header-Profile-Exposed-Nav',
                    url: '/mycarmax/saved-searches',
                    showLoggedIn: true,
                },
                {
                    text: 'My orders',
                    kmxAnalytics: 'prop5=Header-Profile-Exposed-Nav',
                    url: '/checkout',
                    showLoggedIn: true,
                },
                {
                    text: 'Profile settings',
                    kmxAnalytics: 'prop5=Header-Profile-Exposed-Nav',
                    url: '/mycarmax/profile-settings',
                    showLoggedIn: true,
                },
                {
                    icon: icons.signIn,
                    text: 'Sign in',
                    kmxAnalytics: 'prop5=Header-Profile-Exposed-Nav',
                    url: `/mycarmax/sign-in?returnUrl=${encodeURIComponent(window.location.href)}`,
                    showLoggedIn: false,
                },
                {
                    text: 'Sign out',
                    kmxAnalytics: 'prop5=Header-Profile-Exposed-Nav',
                    url: '/mycarmax/sign-out',
                    showLoggedIn: true,
                }, {
                    icon: icons.register,
                    text: 'Create account',
                    kmxAnalytics: 'prop5=Header-Profile-Exposed-Nav',
                    url: '/mycarmax/register',
                    showLoggedIn: false,
                },
            ]
        },
    ]
};
